<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row" id="forum">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center mb-4">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6">
                            <div class="all-tabs">
                                <template v-if="can('add-forum-tool')">
                                    <div class="d-flex align-items-center">
                                    <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                                        focusable="false" data-prefix="fa" data-icon="table" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor"
                                        d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                                        </path>
                                    </svg>
                                    <router-link :to="{ name: 'add-forum-tool' }" class="opt">Create Tool</router-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-0">
                        <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy" :per-page="0"
                            :current-page="currentPage" :sort-desc.sync="sortDesc" :filter-included-fields="filterOn"
                            @filtered="onFiltered" responsive="sm" class="table-bordered table-hover" :key="key">
                            
                            <template v-slot:cell(forum_id)="row">
                                {{row.item.forum_name}}
                            </template>
                            <template v-slot:cell(forum_logo)="row">
                                <img style="max-width: 200px;" :src="row.item.logo_image" alt="" class="forum_logo">
                            </template>
                            <template v-slot:cell(forum_banner)="row">
                                <img style="max-width: 200px;" :src="row.item.banner_image" alt="" class="forum_logo">
                            </template>
                            <template v-slot:cell(settings)="row">
                                <router-link :to="{ name: 'settings', params: { id: row.item.id, forum_id: row.item.forum_id } }">
                                    <i class="fa fa-cogs cogs-i"></i>
                                </router-link>
                            </template>
                            <template v-slot:cell(edit)="row">
                                <router-link :to="{ name: 'edit-forum-tool', params: { id: row.item.id } }">
                                    <i class="fa fa-edit edit-i"></i>
                                </router-link>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../mixins/request";
  import forumToolMixin from "../../../mixins/ModuleJs/forum-tool";
  
  export default {
    mixins: [MixinRequest, forumToolMixin],
    data() {
      return {
        title: "Forum Tool Management",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "List",
          },
        ],
      };
    },
    components: {
      Layout,
      PageHeader,
    },
  };
  </script>
  